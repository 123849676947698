import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  TimeSeriesSet,
  TimeSeries,
  TimePeriod,
  BooleanTimeSeries,
} from '../../models/timeseries.model';
import {
  extractTimePeriods,
  conditionalMean,
} from '../../utils/time-period-utils';
import { KPIs, OperatingConditions } from '../../../assets/settings/measurable';
import { Measurable } from '../../../assets/settings/models/measurable.model';
import { DateConverter } from '../../utils/date-converter';

const DateTimeFormat = 'YYYY-MM-dd HH:mm';

interface TagMap {
  [tag: string]: string;
}

export interface KpiItem {
  run: number;
  starttime: string;
  endtime: string;
  KPI_HeatEffectMainHeaterPV: string;
  KPI_CoolingIceWaterPV: string;
  KPI_CompElecInputPV: string;
  COP: string;
}

export interface ParamTableItem {
  run: number;
  starttime: string;
  endtime: string;
  MainAirTempBeforePreheaterPV: string;
  MainAirFlowRatePV: string;
  CowWaterFlowPV: string;
  CowWaterTempPV: string;
  IceWaterTempBeforeBoosterPV: string;
  CondFlowEcoPV: string;
  CondTempBeforeEcoPV: string;
}

@Component({
  selector: 'app-runs-table',
  templateUrl: './runs-table.component.html',
  styleUrl: './runs-table.component.scss',
})
export class RunsTableComponent implements OnInit, OnChanges {
  @Input() timeseries: TimeSeriesSet = {};
  @Input() modeTimeSeries: TimeSeries = {};
  KpiDisplayedColumns: string[] = [
    'run',
    'starttime',
    'endtime',
    'KPI_HeatEffectMainHeaterPV',
    'KPI_CoolingIceWaterPV',
    'KPI_CompElecInputPV',
    'COP',
  ];
  ParamsDisplayedColumns: string[] = [
    'run',
    'starttime',
    // 'endtime',
    'MainAirTempBeforePreheaterPV',
    'MainAirFlowRatePV',
    'CowWaterFlowPV',
    'CowWaterTempPV',
    'IceWaterTempBeforeBoosterPV',
    'CondFlowEcoPV',
    'CondTempBeforeEcoPV',
  ];
  tableDataKpi: KpiItem[] = [];
  tableDataParams: ParamTableItem[] = [];
  runs: TimePeriod[] = [];
  booleanRuns: BooleanTimeSeries = {};
  boolruns: BooleanTimeSeries = {};
  timestamps: string[] = [];
  displayTags: TagMap = {};
  // timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone!;

  constructor(private dateConverter: DateConverter) {}

  getBooleanMode(mode: TimeSeries, value: number): BooleanTimeSeries {
    const modeBoolean: BooleanTimeSeries = {};
    Object.keys(mode).forEach((key) => {
      modeBoolean[key] = this.modeTimeSeries[key] === value;
    });
    return modeBoolean;
  }

  getRunPeriods(): void {
    // Extract time periods for runs (1 run = mode===2)
    this.runs = extractTimePeriods(this.getBooleanMode(this.modeTimeSeries, 2));
    this.tableDataKpi = [];
    this.tableDataParams = [];
    this.runs.map((run, index) => {
      let runStartDate = new Date(run.start);
      let runEndDate = new Date(run.end);

      let runStartDateTime = this.dateConverter.toFormatUTC(
        runStartDate,
        DateTimeFormat,
      );
      let runEndDateTime = this.dateConverter.toFormatUTC(
        runEndDate,
        DateTimeFormat,
      );

      let flag: BooleanTimeSeries = this.getBooleanMode(this.modeTimeSeries, 2);
      Object.keys(flag).forEach((key) => {
        let keyDate = new Date(key);
        if (keyDate >= runStartDate && keyDate <= runEndDate) {
          2;
          flag[key] = flag[key];
        } else {
          flag[key] = false;
        }
      });

      let KPI_HeatEffectMainHeaterPV = conditionalMean(
        this.timeseries['KPI_HeatEffectMainHeaterPV'],
        flag,
      );
      let KPI_CoolingIceWaterPV = conditionalMean(
        this.timeseries['KPI_CoolingIceWaterPV'],
        flag,
      );
      let KPI_CompElecInputPV = conditionalMean(
        this.timeseries['KPI_CompElecInputPV'],
        flag,
      );
      let COP = conditionalMean(this.timeseries['COP'], flag);

      this.tableDataKpi.push({
        run: index + 1,
        starttime: runStartDateTime,
        endtime: runEndDateTime,
        KPI_HeatEffectMainHeaterPV: KPI_HeatEffectMainHeaterPV.toFixed(2),
        KPI_CoolingIceWaterPV: KPI_CoolingIceWaterPV.toFixed(2),
        KPI_CompElecInputPV: KPI_CompElecInputPV.toFixed(2),
        COP: COP.toFixed(2),
      });

      let MainAirTempBeforePreheaterPV = conditionalMean(
        this.timeseries['MainAirTempBeforePreheaterPV'],
        flag,
      );
      let MainAirFlowRatePV = conditionalMean(
        this.timeseries['MainAirFlowRatePV'],
        flag,
      );
      let CowWaterFlowPV = conditionalMean(
        this.timeseries['CowWaterFlowPV'],
        flag,
      );
      let CowWaterTempPV = conditionalMean(
        this.timeseries['CowWaterTempPV'],
        flag,
      );
      let IceWaterTempBeforeBoosterPV = conditionalMean(
        this.timeseries['IceWaterTempBeforeBoosterPV'],
        flag,
      );
      let CondFlowEcoPV = conditionalMean(
        this.timeseries['CondFlowEcoPV'],
        flag,
      );
      let CondTempBeforeEcoPV = conditionalMean(
        this.timeseries['CondTempBeforeEcoPV'],
        flag,
      );

      this.tableDataParams.push({
        run: index + 1,
        starttime: runStartDateTime,
        endtime: runEndDateTime,
        MainAirTempBeforePreheaterPV: MainAirTempBeforePreheaterPV.toFixed(2),
        MainAirFlowRatePV: MainAirFlowRatePV.toFixed(2),
        CowWaterFlowPV: CowWaterFlowPV.toFixed(2),
        CowWaterTempPV: CowWaterTempPV.toFixed(2),
        IceWaterTempBeforeBoosterPV: IceWaterTempBeforeBoosterPV.toFixed(2),
        CondFlowEcoPV: CondFlowEcoPV.toFixed(2),
        CondTempBeforeEcoPV: CondTempBeforeEcoPV.toFixed(2),
      });
    });
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.displayTags = {};
    KPIs.map((kpi: Measurable) => {
      this.displayTags[kpi.tag] = kpi.name;
    });
    OperatingConditions.map((op: Measurable) => {
      this.displayTags[op.tag] = op.name;
    });
    this.getRunPeriods();
  }
}
