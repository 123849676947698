<mat-form-field appearance="outline" class="monthselector">
  <mat-label>Select Monthly Report</mat-label>
  <mat-select
    [value]="selectedMonth"
    (selectionChange)="onDropdownChange($event.value)"
  >
    <mat-option *ngFor="let month of months" [value]="month.value">{{
      month.label
    }}</mat-option>
  </mat-select>
</mat-form-field>

<span class="reportstatus">{{ reportready ? "" : "Generating Report" }}</span>
<span *ngIf="!reportready" class="smallspinner">
  <p-progressSpinner
    animationDuration="2s"
    strokeWidth="4"
    [style]="{
      width: '50px',
      height: '50px',
      margin: '0px'
    }"
  >
  </p-progressSpinner>
</span>

<mat-expansion-panel [expanded]="true" hideToggle="true" *ngIf="reportready">
  <mat-expansion-panel-header>
    <mat-panel-title>Aggregated Performance </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-grid-list [cols]="colsStatus$ | async" rowHeight="380px">
    <mat-grid-tile *ngFor="let tag of statusTags" [colspan]="1" [rowspan]="1">
      <mat-card class="valuecard">
        <app-value-display
          [title]="statusDisplay[tag].title"
          [subtitle]="statusDisplay[tag].subtitle"
          [value]="statusDisplay[tag].value"
          [uom]="statusDisplay[tag].uom"
          [values]="statusDisplay[tag].values"
        ></app-value-display>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</mat-expansion-panel>

<mat-expansion-panel [expanded]="true" hideToggle="true" *ngIf="reportready">
  <mat-expansion-panel-header>
    <mat-panel-title>KPI</mat-panel-title>
  </mat-expansion-panel-header>

  <mat-grid-list [cols]="chartCols$ | async" rowHeight="500px">
    <mat-grid-tile *ngFor="let tag of kpiTags" [colspan]="1" [rowspan]="1">
      <app-line
        [id]="kpiCharts[tag].id"
        [tag]="tag"
        [lineconfig]="kpiCharts[tag].linechartconfig"
        [timeseries]="kpiCharts[tag].data"
        [upper]="kpiCharts[tag].upper"
        [lower]="kpiCharts[tag].lower"
        [valid]="kpiCharts[tag].validPeriods"
        [showmean]="true"
      ></app-line>
    </mat-grid-tile>
  </mat-grid-list>
</mat-expansion-panel>

<mat-expansion-panel [expanded]="true" hideToggle="true" *ngIf="reportready">
  <mat-expansion-panel-header>
    <mat-panel-title>Average Operating Conditions</mat-panel-title>
  </mat-expansion-panel-header>

  <mat-grid-list [cols]="cols$ | async" rowHeight="320px">
    <mat-grid-tile *ngFor="let tag of avgParams" [colspan]="1" [rowspan]="1">
      <app-gauge
        [id]="paramGauges[tag].tag"
        [gaugecfg]="paramGauges[tag].config"
        [value]="paramGauges[tag].value"
      ></app-gauge>
    </mat-grid-tile>
  </mat-grid-list>
</mat-expansion-panel>
